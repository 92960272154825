.top-campaigns-nav-report {
  height: 7vw;
  position: fixed;
  margin: 0;
  width: 73%;
  display: flex;
  direction: rtl;
  z-index: 999;
  background-color: white;
  left: 5.5vw;
  align-items: center;
  animation: slideDown 0.6s both;
}

.top-companies-nav-report:hover{
  cursor: grab;
}

@keyframes slideDown {
  0% {
    top: -50px;
  }
  25% {
    top: -20px;
  }
  100% {
    top: 0;
  }
}

.no-selected-button {
  background-color: transparent;
  width: 3.8vw;
  height: 3.8vw;
  border-radius: 100%;
  outline: none;
  color: #a2a1a1;
  border: 2.8px solid #c8c8c8;
  margin: 1vw;
  font-size: 2.3vw;
}

.campaigns-logo {
  position: absolute;
  left: 0;
  top: 1.4vw;
  width: 10vw;
}

.campaigns-top-scroll {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 0.3vw;
}

.campaigns-buttons::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.campaigns-inside-client-btn:hover {
  color: white;
}

.mobile .campaigns-client-btn:hover{
  background-color: black;
}
.back-to-home-page {
  text-decoration: none;
  color: black;
}

.back-to-home-page:hover {
  color: white;
}

.campaigns-client-btn {
  background-color: white;
  color: black;
  white-space: nowrap;
  outline: none;
  border: 2px solid black;
  border-radius: 31px;
  height: 2.8vw;
  margin: 0.7vw;
  text-transform: uppercase;
  padding: 0.7vw;
  float: right;
  display: flex;
  justify-content: center;
  /* line-height: 0; */
  justify-content: flex-start;
  font-size: 16px;
  font-family: 'Futura' !important;
  position: relative;
  align-items: center;
}

.campaigns-buttons {
  float: right;
  margin: 10px;
  width: auto;
  max-width: 70%;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
}

.add-client-span {
  text-decoration: underline;
  font-size: 17px;
  margin: 2vw 0;
}

.add-client-span:hover {
  cursor: pointer;
}


.next-btn {
  background-color: rgb(255 47 82);
  border-radius: 100%;
  outline: none;
  border: none;
  color: white;
  width: 3vw;
  height: 3vw;
  font-size: 1vw;
  margin: 1vw;
}
.next-btn:hover {
  cursor: pointer;
}

.campaigns-client-btn:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.campaigns-client-btn:hover .campaigns-remove-btn {
  opacity: 1;
}

.link-to-report-maker {
  margin: 1vw;
}

.campaigns-remove-btn {
  opacity: 0;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  outline: none;
  border: 1px solid black;
  height: 1vw;
  width: 1vw;
  font-size: 0.8vw;
  right: 0.4vw;
  bottom: 2vw;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.campaigns-remove-btn > span {
  position: relative;
  font-size: 0.7vw;
}

.campaigns-remove-btn:hover {
  background-color: #f84a72;
  border: 1px solid #f84a72;
  color: white;
  cursor: pointer;
  transform: scale(1.25);
  transition: 0.2s;
}

.campaigns-inside-client-btn {
  position: relative;
}

.campaigns-start-of-buttons-section {
  position: absolute;
  height: 100%;
  right: 0;
  height: 3vw;
  width: 7vw;
  top: 1.4vw;
}

.campaigns-end-of-buttons-section {
  position: absolute;
  height: 3vw;
  width: 3vw;
  top: 1.4vw;
  width: 4vw;
  left: 20.4vw;
}

.campaigns-more-buttons-icon {
  position: absolute;
  right: 70%;
  font-weight: 300;
  font-size: 4.5vw;
  top: 0;
}


.mobile .top-campaigns-nav-report {
  height: 43vw;
  position: fixed;
  margin: 0;
  width: 100vw;
  display: flex;
  direction: rtl;
  z-index: 999;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  animation: slideDown 0.6s both;
  left: 0;
  flex-direction: column;
}

.mobile .campaigns-logo {
  position: absolute;
  left: 50%;
  top: 4vw;
  width: 28vw;
  transform: translateX(-50%);
}

.mobile .campaigns-client-btn {
  background-color: white;
  color: black;
  white-space: nowrap;
  outline: none;
  border: 2px solid black;
  border-radius: 31px;
  height: 8vw;
  margin: 0.7vw;
  text-transform: uppercase;
  padding: 3.5vw;
  float: right;
  display: flex;
  justify-content: center;
  justify-content: flex-start;
  font-size: 14px;
  font-family: 'Futura' !important;
  position: relative;
  align-items: center;
}

.mobile .campaigns-buttons {
  float: right;
  margin: 10px;
  width: auto;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
}


.mobile .campaigns-buttons {
  float: right;
  margin: 0;
  width: auto;
  max-width: 100%;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;
  top: 7vw;
  position: relative;
}

.underline{
  text-decoration: underline;
}

.mobile .campaigns-top-scroll {
  position: absolute;
  background-color: black;
  width: 100%;
  height: 1vw;
}

.mobile .campaigns-more-buttons-icon {
 display: none !important;
}

.mobile .logout-span {
  font-size: 5vw;
  margin: 2vw 0;
  position: absolute;
  left: 0;
  top: 0;
  text-transform: uppercase;
}


.dates-on-report {
  font-size: 16px;
  top: 5vw;
  position: relative;
}