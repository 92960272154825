.report {
    height: 100%;
  }
  
  
  .header {
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 999;
  }
  
  .report > div> main {
    width: 78%;
    padding: 1vw;
  }
  
  .mobile .report > div> main {
    width: 100%;
    padding: 1vw;
}


.mobile #root::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  
