.full-screen-product-conatiner {
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  right: 0;
  z-index: 10000000;
  background-color: #000000d7;
  animation: fadeIn 1s both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.small-product-conatiner {
  background-color: white;
  height: 606px;
  max-height: 80%;
  width: 1301px;
  max-width: 70%;
  position: relative;
  display: flex;
  padding: 3vw;
}

.image-campaign {
  width: 300px;
  max-width: 350px;
}

#primisPlayerContainerDiv {
  width: 600px !important;
}

.right-area {
  width: 30%;
  opacity: 0;
  padding: 2vw;
}

.left-area {
  width: 70%;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  overflow: hidden;
  position: relative;
}

.bars-area {
  height: 95px;
}

.bars-area span {
  display: none;
}

.bar-title {
  position: relative;
  direction: rtl;
  text-transform: uppercase;
  top: 2vw;
}

.type-title {
  direction: rtl;
  position: relative;
  margin: 0;
  padding: 0;
}

.campaign-name-area {
  direction: rtl;
  position: relative;
  margin: 0;
  padding: 0;
}

.titlesInRightArea {
  display: flex;
  direction: rtl;
}

.grid-product {
  column-count: 2;
  grid-column-gap: 0;
  column-gap: 0;
  height: auto;
  width: 600px;
}

.product-img1 {
  display: inline-block;
  position: absolute;
  top: 0;
  width: auto;
  max-width: 59%;
  max-height: 80%;
  left: 2vw;
}

.carrousle-area{
  overflow: initial !important;
}
.product-img2 {
  display: inline-block;
  position: absolute;
  top: 0;
  width: auto;
  max-width: 59%;
  right: 0;
}

.mobile .product-img1,
.mobile .product-img2,
.mobile .product-img3,
.mobile .product-img4 {
  position: relative;
  left: 0;
  margin: 3vw 0;
}

.mobile .left-area {
  width: 90%;
  justify-content: center;
  align-items: center;
  padding: 2vw;
  overflow: auto;
  position: absolute;
  top: 20vw;
  max-height: 83%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.carouslle-img{
  width: 13vw;
  margin: 1px;
}

.carouslle {
  display: flex;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}
/* .left-area > div {
  background-color: transparent !important;
} */


.mobile .small-product-video-conatiner .left-area {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0;
  overflow: auto;
  height: 100%;
  position: absolute;
  top: 13vw;
  /* max-height: 143%; */
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.mobile .indiana-scroll-container--native-scroll {
  overflow: auto;
  max-width: 96%;
  top: 80vw;
  position: relative;
}

.scrolling-left {
  position: absolute;
  background-color: black !important;
  width: 5px;
  right: 1.7vw;
  top: 0;
}

.mobile .small-product-conatiner {
  background-color: white;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  padding: 3vw;
}

.mobile .scrolling-left, 
.mobile .scrolling-right {
  display: none;
}

.scrolling-right{
  position: absolute;
  background-color: black !important;
  width: 5px;
  left: 1.7vw;
  top: 0;
}

.mobile .small-product-video-conatiner {
  background-color: white;
  height: 300px;
  max-height: 80%;
  width: 1301px;
  max-width: 70%;
  position: relative;
  display: flex;
}

.mobile #primisPlayerContainerDiv {
  width: 90%!important;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100% !important;
  transform: translateX(-50%) translateY(-50%);
}

.mobile #videoContainerDiv{
  width: 80% !important;
  height: 100%;
  background: transparent !important;
  position: relative;
  z-index: 1;
}

.videos .left-area {
  display: flex;
  justify-content: flex-start;
}

.mobile .carouslle-img {
  width: 77vw;
  margin: 1px;
}

.mobile .left-area::-webkit-scrollbar{
  width: 0%;
  height: 0;
}

.left-area > div > div {
  display: block;
  margin: 0px;
  padding: 0px;
  border: 0px;
  position: absolute;
  overflow: hidden;
  width: 60% !important;
  height: 90% !important;
}
.videos .left-area > div > div {
  margin: 5px;
  position: relative;
}
.videos .carrousle-area{
  overflow: auto !important;
}

.mobile .small-product-conatiner .MuiSvgIcon-root,
.mobile .small-product-video-conatiner .MuiSvgIcon-root
 {
  width: 5em;
  height: 5em;
  font-size: 1.5vw;
  left: initial;
  position: absolute;
  right: 50%;
  bottom: initial;
  color: black;
  transform: translateX(50%) translateY(-50%);
  top: 50%;
}

.product-img3 {
  display: inline-block;
  position: absolute;
  bottom: 0;
  width: auto;
  max-width: 59%;
  left: 2vw;
}

.product-img4 {
  display: inline-block;
  position: absolute;
  bottom: 0;
  width: auto;
  max-width: 59%;
  right: 0;
}

.product-rate {
  width: 6vw;
  height: 3vw;
  border-radius: 30px;
  background-color: #ffdb48;
  margin: 1vw;
  font-size: 2vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-product-pop-up-btn {
  width: 1.9vw;
  height: 1.9vw;
  margin: 1vw;
  position: absolute;
  left: 1vw;
  top: 1vw;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: 2px solid black;
}
.mobile .close-product-pop-up-btn {
  width: 8vw;
  height: 8vw;
  margin: 1vw;
  position: absolute;
  left: 4vw;
  top: 4vw;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: 2px solid black;
}

.close-product-pop-up-btn:hover {
  cursor: pointer;
  background-color: #f84a72;
  border: 2px solid #f84a72;
  color: white;
}


.carr-icon {
  position: absolute;
  bottom: 0;
  z-index: 9999;
  width: 13vw;
  right: 50%;
  transform: translateX(50%);
  /* animation: Blink 5s both; */
}

@keyframes Blink {
  0%{
    opacity: 0;
  }
  20%{
    opacity: 1;
  }
  40%{
    opacity: 0;
  }
  60%{
    opacity: 1;
  }
  80%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.arrows-bottom{
  display: none;
}

.mobile .arrows-bottom{
  display: block;
  position: absolute;
  bottom: 3vw;
  right: 50%;
  transform: translateX(50%);
}