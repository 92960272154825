.bottom-bar-area {
  position: fixed;
  bottom: 0;
  z-index: 99999;
  background-color: #fff;
  width: 100vw;
  border: none;
  border-top: 1px solid black;
}

.wave {
  background-color: #fff;
  width: 70px;
  height: 35px;
  position: fixed;
  bottom: 14vw;
  right: 50%;
  transform: translateX(50%);
  border: 1px solid black;
  border-bottom: 2px solid white;
  background: #fff;
  display: inline-block;
  border-top-left-radius: 70px;
  border-top-right-radius: 70px;
}

.funnel-icon {
  z-index: 999;
  position: absolute;
  right: 50%;
  top: 3vw;
  transform: translateX(50%);
  width: 25px;
}

.filtering-title {
  position: absolute;
  right: 50%;
  font-size: 16px;
  top: 2vw;
  transform: translateX(50%);
}

.open-bar {
  animation: SlideUp 1s both;
}

.close-bar {
  animation: SlideDown 1s both;
}


.opened-bar-bottom {
  animation: SlideUpForBottom 1s both;
}

.close-bar-bottom {
  animation: SlideDownForBottom 1s both;
}

.mobile .lightbox-bg {
  position: fixed;
  height: 100%;
  background-color: rgb(0 0 0 / 58%);
  width: 100vw;
  top: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(5px);
  animation: FadeIn 1s both;
}

.mobile .close-lightbox {
  position: fixed;
  height: 100%;
  background-color: rgb(0 0 0 / 58%);
  width: 100vw;
  top: 0;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(5px);
  animation: FadeOut 1s both;
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    z-index: -10;
  }
  10% {
    z-index: 999;
  }

  100% {
    opacity: 1;
    z-index: 999;
  }
}
@-webkit-keyframes FadeIn {
  0% {
    opacity: 0;
    z-index: -10;
  }
  10% {
    z-index: 999;
  }

  100% {
    opacity: 1;
    z-index: 999;
  }
}

@keyframes FadeOut {
  0% {
    opacity: 1;
    z-index: 9999;
  }
  90% {
    z-index: 9999;
  }

  100% {
    opacity: 0;
    z-index: -10;
  }
}
@-webkit-keyframes FadeOut {
  0% {
    opacity: 1;
    z-index: 9999;
  }

  90% {
    z-index: 9999;
  }

  100% {
    opacity: 0;
    z-index: -10;
  }
}
/* 
@keyframes FadeOut {
  0% {
    opacity: 1;
  }
  10%{
    opacity: 0;
  }

  100% {
    opacity: 0;
    z-index: -10;
  }
}
@-webkit-keyframes FadeOut {
  0% {
    opacity: 1;
  }
  10%{
    opacity: 0;
  }

  100% {
    opacity: 0;
    z-index: -10;
  }
} */

@keyframes SlideUp {
  0% {
    height: 14vw;
  }

  100% {
    height: 150vw;
  }
}
@-webkit-keyframes SlideUp {
  0% {
    height: 14vw;
  }

  100% {
    height: 150vw;
  }
}

@keyframes SlideDown {
  0% {
    height: 150vw;
  }

  100% {
    height: 14vw;
  }
}
@-webkit-keyframes SlideDown {
  0% {
    height: 150vw;
  }

  100% {
    height: 14vw;
  }
}

@keyframes SlideUpForBottom {
  0% {
    bottom: 14vw;
  }

  100% {
    bottom: 150vw;
  }
}
@-webkit-keyframes SlideUpForBottom {
  0% {
    bottom: 14vw;
  }

  100% {
    bottom: 150vw;
  }
}
@keyframes SlideDownForBottom {
  0% {
    bottom: 150vw;
  }

  100% {
    bottom: 14vw;
  }
}
@-webkit-keyframes SlideDownForBottom {
  0% {
    bottom: 150vw;
  }

  100% {
    bottom: 14vw;
  }
}
