.full-screen-link-conatiner {
  width: 100%;
  height: 100%;
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  right: 0;
  z-index: 10000000;
  background-color: #000000d7;
  animation: fadeIn 1s both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.small-link-conatiner {
  background-color: white;
  height: 206px;
  max-height: 30%;
  width: 701px;
  max-width: 40%;
  position: relative;
  padding: 3vw;
  text-align: center;
}


.copy-link-btn:disabled{
  background-color: #fd7292;
  border: 1px solid #fd7292;
}

.send-on-email-btn{
  background-color: black;
  border: 1px solid black;
  width: 10vw;
  height: 2.6vw;
  margin: 2vw;
  font-size: 1vw;
  color: white;
  font-weight: bold;
}

.send-on-email-btn:hover{
  cursor: pointer;
}

.close-link-pop-up-btn {
  width: 1.9vw;
  z-index: 100000;
  height: 1.9vw;
  margin: 1vw;
  position: absolute;
  left: 1vw;
  top: 1vw;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: 2px solid black;
}

.close-link-pop-up-btn:hover {
  cursor: pointer;
  background-color: #f84a72;
  border: 2px solid #f84a72;
  color: white;
}

.mobile .small-link-conatiner {
  background-color: white;
  height: 506px;
  max-height: 30%;
  width: 901px;
  max-width: 70%;
  padding: 3vw;
  text-align: center;
}

.url-box {
  height: 2vw;
  width: 80%;
  outline: none;
  border: none;
  direction: ltr;
  border-bottom: 5px solid black;
  font-size: 1vw;
}

.inner-content-second{
  transform: translateX(-1300px);
  position: absolute;
  bottom: 0px;
}

.out{
  animation: SlideOut 1s both;
}

.in{
  animation: SlideIn 1s both;
}

@keyframes SlideOut{
  0%{
    transform: translateX(0);
    opacity: 1;
  }
  30%{
    opacity: 0;
  }

  100%{
    transform: translateX(200px);
    opacity: 0;
  }
}

@keyframes SlideIn{
  0%{
    transform: translateX(-200px);
    opacity: 0;
  }
  90%{
    opacity: 1;
  }

  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

.report-name-box{
  height: 2vw;
  width: 80%;
  outline: none;
  border: none;
  direction: rtl;
  border-bottom: 5px solid black;
  font-size: 2vw;
}

.link-title {
  color: black;
  font-size: 1.5vw;
  font-weight: 800;
}

.mobile .url-box {
  height: 9vw;
  width: 85%;
  outline: none;
  border: none;
  direction: ltr;
  border-bottom: 4.5px solid black;
  font-size: 4vw;
}

.copy-link-btn {
  background-color: #f84a72;
  border: 1px solid #f84a72;
  width: 10vw;
  height: 2.6vw;
  margin: 2vw;
  font-size: 1vw;
  color: white;
  font-weight: bold;
}

.copy-link-btn:hover {
  cursor: pointer;
}

.mobile .close-link-pop-up-btn {
  width: 5vw;
  height: 5vw;
  margin: 1vw;
  position: absolute;
  left: 15vw;
  top: 63vw;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  outline: none;
  border: 2px solid black;
}

.mobile .close-link-pop-up-btn .MuiSvgIcon-root {
  width: 0.8em;
  height: 0.8em;
}

.mobile .link-title {
  color: black;
  font-size: 4.5vw;
  font-weight: 800;
}

.mobile .copy-link-btn {
  background-color: #f84a72;
  border: 1px solid #f84a72;
  width: 60.8vw;
  height: 9vw;
  margin: 14vw 2vw;
  font-size: 4vw;
  color: white;
  font-weight: bold;
}
