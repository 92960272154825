@font-face {
  font-family: "AlmoniLight";
  src: url("fonts/almoni-dl-aaa-light.woff");
}
@font-face {
  font-family: "AlmoniNormal";
  src: url("fonts/almoni-neue-regular-aaa.woff");
}
@font-face {
  font-family: "FuturaMedium";
  src: url("fonts/futuraMediumBT.woff");
}
@font-face {
  font-family: "Futura";
  src: url("fonts/FuturaStdMedium.woff");
}

body,
html,
#root {
  background-color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  font-family: 'AlmoniNormal';
  font-family: 'AlmoniNormal';

}

body > * {
  font-family: 'AlmoniNormal';
}

body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

button,
input {
  font-family: 'AlmoniNormal';
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

::-webkit-scrollbar-thumb {
  background: rgb(36, 35, 35);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(0, 0, 0);
  transition: 0.2s;
}
