.report-maker {
  height: 100%;
}


.header {
  position: fixed;
  top: 0;
  margin: 0;
  z-index: 999;
}

.report-maker > main {
  width: 78%;
  padding: 1vw;
}

.mobile .report-maker > main {
  width: 100%;
  padding: 1vw;
}
